
const data= [
    {id:'01',original:'Zesty Sparkle',qty:0,order:false},
    {id:'02',original:'Tangy Green',qty:0,order:false},
    {id:'03',original:'Palm Rounds',qty:0,order:false},
    {id:'04',original:'Crisp Green',qty:0,order:false},
    {id:'05',original:'Jasmine Green Tea',qty:0,order:false},
    {id:'06',original:'Golden Crystal',qty:0,order:false},
    {id:'07',original:'Mellow Melody',qty:0,order:false},
    {id:'08',original:'Sunset Paradise',qty:0,order:false},
    {id:'09',original:'Orchard Rounds',qty:0,order:false},
    {id:'10',original:'Exotic Passion',qty:0,order:false},
    {id:'11',original:'White Freeze',qty:0,order:false},
    {id:'12',original:'Fresh Red',qty:0,order:false},
    {id:'13',original:'Dark Sparkle',qty:0,order:false},
    {id:'14',original:"Garden's Heart",qty:0,order:false},
    {id:'15',original:'Tangy Purple',qty:0,order:false},
    {id:'16',original:'Sunny Sparkle',qty:0,order:false},
    {id:'17',original:'Hawaiian Sunshine',qty:0,order:false},
    {id:'18',original:'Ludou Ice',qty:0,order:false},
    {id:'19',original:'Golden Slice',qty:0,order:false},
    {id:'20',original:'Green Zest Tobacco',qty:0,order:false},
    {id:'21',original:'Rich Tobacco',qty:0,order:false},
    {id:'22',original:'Menthol Plus',qty:0,order:false},
    {id:'23',original:'Menthol Xtra',qty:0,order:false}
]

export default data