import React, { Component } from 'react'

import '../PodList/index.css'

import relxOrder from '../../Data/relxPodOrder'

export default class Container extends Component {
  
    state={
        relxData:relxOrder,
        isOrder:false
    }

    increment=(e)=>{
        //console.log(e)
        const {relxData}=this.state
        const newOrder=relxData.map((relxFlavor)=>{
           if(relxFlavor.id===e) return{...relxFlavor,qty:relxFlavor.qty+1}
           else return relxFlavor
        })
        this.setState({relxData:newOrder})
        //console.log(newOrder)
     
    }

    decrement=(e)=>{
        const {relxData}=this.state
        const newOrder=relxData.map((relxFlavor)=>{
           if(relxFlavor.id===e) return{...relxFlavor,qty:relxFlavor.qty-1}
           else return relxFlavor
        })
        this.setState({relxData:newOrder})
    }

    deleteOrder=(id)=>{
     // console.log(id)
      const {relxData}=this.state
      const newOrder=relxData.filter((relxFlavor)=>{
          return relxFlavor.id!==id
      })
      this.setState({relxData:newOrder})
    }

    orderSave=()=>{
        const {relxData}=this.state
        const newOrder=relxData.map((relxFlavor)=>{
           if(relxFlavor.order===false) return{...relxFlavor,order:true}
           else return relxFlavor
        })
        this.setState({relxData:newOrder,isOrder:true})
        //console.log(this.state.relxData)
    }

    render() {
        const{relxData}=this.state
        //console.log(relxData)
        const pods=relxData.map((pods)=>{     
            return(
               <tr key={pods.id}>
                   <td>{pods.original}</td>
                   <td>
                   <button style={{display:pods.order?'none':''}} onClick={()=>this.decrement(pods.id)}>-</button>
                   {/* <input className='inputQty text-primary' value={pods.qty} readOnly /> */}
                  <b><span className='text-primary qty'>{pods.qty}</span></b>

                   <button style={{display:pods.order?'none':''}} onClick={()=>this.increment(pods.id)}>+</button>
                   </td>
                   <td>
         
                   <button style={{display:pods.order?'none':''}} className='btn btn-sm btn-outline-danger' onClick={()=>this.deleteOrder(pods.id)}>Delete</button>
                   </td>
               </tr>
           )
        })

     

        return (
            <div className="row">
              <div className="col">
                <table className="table table-striped table-hover">
                   <thead>
                   {this.state.isOrder?(
                   <tr>
                     <td>Flavor</td>
                     <td>Order Qty</td>           
                  </tr>
                  ):( <tr>
                     <td>Flavor</td>
                     <td>Order Qty</td>
                     <td>Action</td>   
                  </tr>)
                  }
                 </thead>
                 <tbody>
                    {pods}
                 </tbody>
                </table>
                <button className='btn btn-outline-info text-right' onClick={this.orderSave}>Order</button>
                </div>
            </div>
        )
    }
}
