import React, { Component } from 'react'
import moment from 'moment'




export default class index extends Component {
   state={
       Now:''
   }

   componentDidMount(){
      this.timerID=setInterval(() => 
          {this.tick()},
      1000);
   }

   componentWillUnmount(){
       clearInterval(this.timerID)
   }

   tick=()=>{
       this.setState({
           Now:moment().format('ddd DD MMM yyyy h:mm:ss A')
       })
   }
   
    render() {
      const {Now}=this.state

        return (
            <div className='text-secondary'>
              {Now}
            </div>
        )
    }
}
