import Header from './components/Header'
import PodList from './components/PodList'


import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css'


function App() {
  return (
    <div className="container">
      <Header />
      <PodList />
    </div>
  );
}

export default App;
